(function ($) {

    const header = document.querySelector(".header");
    const navigation = header.querySelector(".banner-navigation");
    const toggles = header.querySelectorAll(".banner-navigation-mobile__toggle");
    const dropdowns = navigation.querySelectorAll(".sub-menu-toggle");

    const getParentNode = (el, className) => {
        while (el && el.parentNode) {
            el = el.parentNode;

            if (el && el.classList && el.classList.contains(className)) {
                return el;
            }
        }

        return null;
    };

    dropdowns.forEach(dropdown => {
        dropdown.addEventListener("click", () => {
            const subMenu = getParentNode(dropdown, 'menu-item-has-children').querySelector(".sub-menu");

            const openSubMenu = document.querySelector(".sub-menu.open");
            if(openSubMenu && !openSubMenu.contains(subMenu)) {
                openSubMenu.classList.remove("open");
            }

            subMenu.classList.toggle("open");

            if(subMenu.classList.contains("open")) {
                header.classList.add("header-dark");
            } else {
                header.classList.remove("header-dark");
            }

            setTimeout(function() {
                document.addEventListener("click", (event) => {

                    const isInHeader = getParentNode(event.target, 'header');

                    if(!isInHeader) {
                        subMenu.classList.remove("open");
                        header.classList.remove("header-dark");
                        dropdown.classList.remove("open");
                    }

                }, {
                    once: true
                });
            }, 500);

        });
    });

    toggles.forEach(toggle => {
        toggle.addEventListener("click", () => {
            navigation.classList.toggle("open");
        });
    });

    const headerBackground = () => {
        let windowWidth = window.innerWidth;

        if(windowWidth < 1200) {
            header.classList.add("header-dark");
        } else {
            header.classList.remove("header-dark");
        }
    };

    window.addEventListener('resize', headerBackground, true);

    headerBackground();

})(jQuery);
