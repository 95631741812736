/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                //Information Modal
                if ( $('.comp-information-modal').length > 0) {
                    $('.comp-information-modal .close-icon').click(function() {
                        var expires = new Date();
                        expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
                        document.cookie = 'information-modal' + '=' + 'read' + ';expires=' + expires.toUTCString();
                        $('.comp-information-modal').fadeOut();
                    });
                }

                $('.mps-tel').click(function() {
                    ga('send', 'event', 'link', 'click', 'Telephone Link');
                });

                if ($(window).width() > 992) {
                    $('.sub-menu-toggle').click(function () {
                        $(this).toggleClass('open');
                    });
                }

                if ($(window).width() < 992) {
                    $('#menu-hamburger .sub-menu > li ').click(function () {
                        $(this).toggle();
                        $(this).find('.sub-menu').toggle();
                    });

                    $('.page-template-template-treatment .side-nav-bar .nav-title').click(function () {
                        $('ul.nav').toggle();
                    });

                    $('.page-template-template-treatment-new .side-nav-bar .nav-title').click(function () {
                        $('ul.nav').toggle();
                    });

                    $('.nav-item').click(function () {
                        $('ul.nav').toggle();
                    });
                }

                // set offset to height of page header container
                let offset = $('.page-header-container').height();

                //scroll to top
                $('body').scrollspy({
                    target: '.side-nav',
                    offset: offset + 20
                });

                const fillSectionTitle = () => {

                    const sectionTitle = document.querySelector(".side-nav .section-title");
                    let firstNavItem = document.querySelector(".side-nav .nav-item:first-child a");
                    let activeNavItem = document.querySelector(".side-nav a.active");

                    if (sectionTitle && firstNavItem && activeNavItem) {
                        if(activeNavItem) {
                            sectionTitle.innerHTML = activeNavItem.innerHTML.trim();
                        } else {
                            sectionTitle.innerHTML = firstNavItem.innerHTML.trim();
                        }
                    }
                };

                $(window).on('activate.bs.scrollspy', function () {
                    fillSectionTitle();
                });

                fillSectionTitle();

                /**
                 * Stats Slider
                 */

                const sliders = document.querySelectorAll(".splide");

                if(sliders.length) {

                    sliders.forEach(slider => {
                        new Splide( slider, {
                            type     : 'loop',
                            perPage: 3,
                            perMove: 1,
                            gap: '20px',
                            destroy: true,
                            arrows: false,
                            autoHeight: true,
                            autoplay: true,
                            interval: 2000,
                            breakpoints: {
                                1200: {
                                    destroy: false,
                                },
                                991: {
                                    perPage: 2,
                                },
                            }
                        } ).mount();
                    });

                }

                // Highlight initial section
                $(window).trigger('activate.bs.scrollspy');

                // Check if home page and remove header class
                if ($('.home').length) {
                    $('.header').removeClass('header-scrolling');

                    $(window).scroll(function () {
                        if ($(window).scrollTop() > 5) {
                            $('.header').addClass('header-scrolling');
                        } else {
                            $('.header').removeClass('header-scrolling');
                        }
                    });
                }

                //testimonial image swap
                $('.sub-image').click(function () {
                    var current = $('.main-image').css("background-image");
                    $('.main-image').css("background-image", $(this).css("background-image"));
                    $(this).css("background-image", current);
                });

                //team swap
                $('.staff-image').click(function () {

                    $('.supportContent').hide();
                    $('#supportTeam' + $(this).data('supportid')).show();

                    $('.staff-image').removeClass('open');
                    $(this).addClass('open');

                });

                //scroll fade in elements

                // if ($('.fade-in').visible(true)) {
                //     $(this).addClass('in-view');
                // } else {
                //     // The element is NOT visible, do something else
                // }
                //
                // $('.fade-in').each(function(){
                //
                //     if ($(this).inView("both")) {
                //
                //         $(this).addClass('in-view');
                //
                //     } else {
                //
                //         $(this).removeClass('in-view');
                //
                //     }
                //
                // });

                // function checkVisability() {
                //     $('.fade-in').each(function(){
                //
                //         if ($(this).inView()) {
                //
                //             $(this).addClass('in-view');
                //
                //         } else {
                //
                //             $(this).removeClass('in-view');
                //
                //         }
                //
                //     });
                // }
                //
                // checkVisability();
                //
                // $(window).scroll(function() {
                //     checkVisability();
                // });


                $('.fade-in').addClass('in-view');

                // $('.fade-in,.fade-in-delay').bind('inview', function (event, visible, topOrBottomOrBoth) {
                //
                //     if (visible == true) {
                //         // element is now visible in the viewport
                //
                //         if (topOrBottomOrBoth == 'top') {
                //             // top part of element is visible
                //         } else if (topOrBottomOrBoth == 'bottom') {
                //             // bottom part of element is visible
                //         } else {
                //             // whole part of element is visible
                //             $(this).addClass('in-view');
                //         }
                //     } else {
                //         // element has gone out of viewport
                //     }
                // });



                //show more gallery
                $('#load-more').click(function () {

                    $(this).hide();
                    $('.carousel').removeClass('hidden');

                });

                //category drop down
                $('#cat-trigger').click(function () {
                    $(this).toggleClass('open');
                    $('.link-cont').toggle();
                });

                /* Responsive Tables */
                function getRandomInt(min, max) {
                    min = Math.ceil(min);
                    max = Math.floor(max);
                    return Math.floor(Math.random() * (max - min)) + min;
                }

                var $tables = $('.text-body > table');

                if ($tables.length) {
                    var $style = $("<style>");

                    $style.prop("type", "text/css");

                    $tables.each(function (index, table) {
                        var $table = $(table);
                        $table.attr('id', 'table' + getRandomInt(1, 10000));
                        $table.find('thead th').each(function (index, th) {
                            var label = $(th).text();
                            $style.append('#' + $table.attr('id') + ' td:nth-of-type(' + (index + 1) + '):before { content: "' + label.replace(/\r?\n|\r/g, '') + '" }');
                        });
                    });

                    $style.appendTo("head");
                }

                //on ajax load more load complete
                $.fn.almComplete = function (alm) {

                    //vid popup
                    $('.youtube-link').magnificPopup({
                        type: 'iframe'
                    });

                };

                //submit form onn checkbx change
                $('.filter-check').change(function () {
                    $('#testimonial-form').submit();
                });

                //Homepage Treatment List
                if ($('.treatment-list').length) {
                    $('.treatment-list ul li:first-of-type, .treatment-text:first-of-type').addClass('active');

                    //Click through treatments
                    $('.treatment-list ul li').click(function() {
                        var treatmentName = $(this).data('name');
                        $('.treatment-list ul li, .treatment-text').removeClass('active');
                        $(this).addClass('active');
                        $('.treatment-text[data-name='+treatmentName+']').addClass('active');
                    });
                }

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page

                $('.award-link').click(function() {
                    ga('send', 'event', 'link', 'click', 'Award Link');
                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },

        'page_template_template_treatment_new': {
            init: function () {

                $('.carousel').each(function() {
                    $(this).magnificPopup({
                        delegate: '.carousel-item',
                        type: 'image',
                        tLoading: 'Loading image #%curr%...',
                        mainClass: 'mfp-img-mobile',
                        gallery: {
                            enabled: true,
                            navigateByImgClick: true,
                            preload: [0,1]
                        },
                        image: {
                            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                            titleSrc: function(item) {
                                return item.el.attr('title') + '<span>' + item.el.parent().data('description') + '</span>';
                            }
                        }
                    });
                });

                $("a").on('click', function(event) {
                    let offset = $('.page-header-container').height();

                    // Make sure this.hash has a value before overriding default behavior
                    if (this.hash !== "") {
                        // Prevent default anchor click behavior
                        event.preventDefault();

                        // Store hash
                        let hash = this.hash;

                        // Using jQuery's animate() method to add smooth page scroll
                        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                        window.jQuery('html, body').animate({
                            scrollTop: window.jQuery(hash).offset().top - offset
                        }, 800, function(){

                            // Add hash (#) to URL when done scrolling (default click behavior)
                            // window.location.hash = hash;
                            // Replace history state with hash
                            history.pushState(null, null, hash);
                        });
                    } // End if
                });

            }

        },


        'page_template_template_treatment': {
            init: function () {

                $('.carousel').each(function() {
                    $(this).magnificPopup({
                        delegate: '.carousel-item',
                        type: 'image',
                        tLoading: 'Loading image #%curr%...',
                        mainClass: 'mfp-img-mobile',
                        gallery: {
                            enabled: true,
                            navigateByImgClick: true,
                            preload: [0,1]
                        },
                        image: {
                            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                            titleSrc: function(item) {
                                return item.el.attr('title') + '<span>' + item.el.parent().data('description') + '</span>';
                            }
                        }
                    });
                });

                $("a").on('click', function(event) {
                    // Make sure this.hash has a value before overriding default behavior
                    if (this.hash !== "") {
                        // Prevent default anchor click behavior
                        event.preventDefault();

                        // Store hash
                        let hash = this.hash;

                        // Using jQuery's animate() method to add smooth page scroll
                        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                        window.jQuery('html, body').animate({
                            scrollTop: window.jQuery(hash).offset().top
                        }, 800, function(){

                            // Add hash (#) to URL when done scrolling (default click behavior)
                            window.location.hash = hash;
                        });
                    } // End if
                });

            }
        },

        'page_template_template_team_php': {
            init: function () {
                // JavaScript to be fired on the about us page

                if ($(window).width() > 1200) {

                    //scrollify
                    $.scrollify({
                        section: ".scrollify-section",
                        setHeights: false,
                        offset : 1,
                        before: function (i) {

                            $('.scroll-trigger').removeClass('active');
                            $('#trigger' + i).addClass('active');

                        },
                        afterRender: function () {
                            var pagination = '<div class="pag-trigger">';
                            var activeClass = "";
                            var county = 0;
                            $(".scrollify-section").each(function (i) {
                                activeClass = "";
                                if (i === 0) {
                                    activeClass = "active";
                                }
                                pagination += '<a id="trigger' + county + '" class="scroll-trigger ' + activeClass + '" href="#' + '" data-target="' + county + '"><div class="circle"></div></a>';
                                county++;
                            });

                            pagination += "</div>";

                            $("body").append(pagination);


                            $(".pag-trigger a").on("click", function () {
                                $.scrollify.move($(this).data("target"));

                                $('.scroll-trigger').removeClass('active');
                                $('#trigger' + $(this).data("target")).addClass('active');
                            });

                        }
                    });

                }


            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
