(function ($) {
    if ($('.page-template-template-home-new').length) {
        var aniTimer = 0.5;

        var home = '.page-template-template-home-new';

        //Page Header
        var compPageHeader = $(home + ' .page-header');

        function pageHeaderAnimations() {
            compPageHeader.each(function () {
                compPageHeader.find('.background-image').addClass('background-zoom');
                var time = 500;
                $(this).find('h1, h2, h3, h4, p').each(function (index) {
                    var self = this;
                    setTimeout(function () {
                        $(self).addClass('visible-show');
                    }, index * 200);
                });
            });
        }

        pageHeaderAnimations();
        $(window).scroll(function () {
            pageHeaderAnimations();
        });

        //Text Image
        var compTextImage = $(home + ' .text-image');

        function textImageAnimations() {
            compTextImage.each(function () {
                if ($(window).scrollTop() > $(this).offset().top * aniTimer) {
                    compTextImage.find('.background-image').addClass('background-zoom');
                    var time = 500;
                    $(this).find('h1, h2, h3, h4, p').each(function (index) {
                        var self = this;
                        setTimeout(function () {
                            $(self).addClass('visible-show');
                        }, index * 200);
                    });
                }
            });
        }

        $(window).scroll(function () {
            textImageAnimations();
        });

        //Logos
        var compLogos = $(home + ' .logos');

        function logosAnimations() {
            compLogos.each(function () {
                if ($(window).scrollTop() > $(this).offset().top * aniTimer) {
                    var time = 500;
                    $(this).find('h1, h2, h3, h4, p, .logo').each(function (index) {
                        var self = this;
                        setTimeout(function () {
                            $(self).addClass('visible-show');
                        }, index * 200);
                    });
                }
            });
        }

        $(window).scroll(function () {
            logosAnimations();
        });

        //Treatments
        var compTreatments = $(home + ' .treatments');

        function treatmentsAnimations() {
            compTreatments.each(function () {
                if ($(window).scrollTop() > $(this).offset().top * aniTimer) {
                    var time = 500;
                    $(this).find('h1, h2, h3, h4, h5, p').each(function (index) {
                        var self = this;
                        setTimeout(function () {
                            $(self).addClass('visible-show');
                        }, index * 200);
                    });
                }
            });
        }

        $(window).scroll(function () {
            treatmentsAnimations();
        });

        //Background parallax
        $(document).ready(function () {
            $('.background-parallax').paroller({
                factor: 0.2,
                type: 'background',
                direction: 'vertical'
            });
        });

        if ($(window).width() > 992) {
            //Header change
            // if ($('.page-template-template-home-new').length) {
            //     var headerHeight = $('.page-header').outerHeight();
            //     $(document).scroll(function () {
            //         if ($(window).scrollTop() > headerHeight) {
            //             $('body').addClass('home-blue');
            //         } else {
            //             $('body').removeClass('home-blue');
            //         }
            //     });
            // }

            //Hero image scroll width
            var frameGrow = 1;
            var heroTop = $('.treatments .hero-image').offset().top * 0.75;
            var heroBottom = $('.treatments .hero-image').outerHeight() + heroTop;

            var topHeight = $('.hero-image .top').outerHeight();
            var bottomHeight = $('.hero-image .bottom').outerHeight();
            var leftWidth = $('.hero-image .left').width();
            var rightWidth = $('.hero-image .right').width();

            $(document).scroll(function () {
                if ($(window).scrollTop() > heroTop && $(window).scrollTop() < heroBottom) {
                    frameGrow++;
                    $('.hero-image .top').outerHeight(topHeight - frameGrow);
                    $('.hero-image .bottom').outerHeight(bottomHeight - frameGrow);
                    $('.hero-image .left').width(leftWidth - frameGrow);
                    $('.hero-image .right').width(rightWidth - frameGrow);
                }
            });
        }
    }
})(jQuery);
